import { useAtomValue } from "jotai/react";
import type { ReactNode } from "react";
import { Link, type LinkProps } from "react-aria-components";
import { WButton } from "../../components/button/WButton.tsx";
import { useConfig } from "../../config.ts";
import { chatProviderAtom } from "./WChatProvider.tsx";

export interface SupportLinkProps {
    children: ReactNode;
    isButton?: boolean;
    color?: "default" | "primary" | "success" | "error" | "muted";
}

export function SupportLink({ isButton, color = "primary", ...forwardProps }: SupportLinkProps) {
    const chatProvider = useAtomValue(chatProviderAtom);
    const { supportEmail } = useConfig();

    const onPress = chatProvider ? () => chatProvider.show() : undefined;

    return isButton ? (
        <WButton color={color} action={onPress ?? `mailto:${supportEmail}`} {...forwardProps} />
    ) : onPress ? (
        <Link onPress={onPress} {...forwardProps} />
    ) : (
        <SupportEmail {...forwardProps} />
    );
}

export function SupportEmail({ children, ...props }: LinkProps) {
    const { supportEmail } = useConfig();
    children ??= supportEmail;
    return (
        <Link href={`mailto:${supportEmail}`} {...props}>
            {children}
        </Link>
    );
}
